<template>
  <div>
    <div class="checkout-container23">
      <h3 class="checkout-text20">{{ $t_("resumo_do_pedido") }}</h3>
    </div>
    <div class="checkout-container24">
      <div class="checkout-container25">
        <div class="checkout-container26">
          <span class="checkout-text21"
            >{{ $t_("subtotal") }} ({{ $carrinho.resumo.qtd }}
            {{ $carrinho.resumo.qtd > 1 ? $t_("itens") : $t_("item") }})</span
          >
        </div>
        <div class="checkout-container27">
          <span class="checkout-text23">{{ $carrinho.resumo.subtotal | moeda }}</span>
          <span class="checkout-text24">,{{ $carrinho.resumo.subtotal | centavos }}</span>
        </div>
      </div>
      <div
        class="checkout-container34"
        v-if="$carrinho.resumo.valor_combo && $carrinho.resumo.valor_combo > 0"
      >
        <div class="checkout-container35">
          <span class="checkout-text33">{{ $t_("desconto_combo") }}</span>
        </div>
        <div class="checkout-container36">
          <small>(-)</small>
          <span class="checkout-text23">{{ $carrinho.resumo.valor_combo | moeda }}</span>
          <span class="checkout-text24"
            >,{{ $carrinho.resumo.valor_combo | centavos }}</span
          >
        </div>
      </div>
      <div class="checkout-container34" v-if="$carrinho.resumo.valor_cupom > 0">
        <div class="checkout-container35">
          <span class="checkout-text33">{{ $t_("desconto_cupom") }}</span>
        </div>
        <div class="checkout-container36">
          <small>(-)</small>
          <span class="checkout-text23">{{ $carrinho.resumo.valor_cupom | moeda }}</span>
          <span class="checkout-text24"
            >,{{ $carrinho.resumo.valor_cupom | centavos }}</span
          >
        </div>
      </div>
      <div class="checkout-container28" v-if="$carrinho.resumo.valor_taxa > 0">
        <div class="checkout-container29">
          <span class="checkout-text25">{{ $t_("taxa") }}</span>
        </div>
        <div class="checkout-container30">
          <small>(+)</small>
          <span class="checkout-text23">{{ $carrinho.resumo.valor_taxa | moeda }}</span>
          <span class="checkout-text24"
            >,{{ $carrinho.resumo.valor_taxa | centavos }}</span
          >
        </div>
      </div>
      <div
        class="checkout-container28"
        v-if="protect && protect.quote_protect_group.sold"
      >
        <div class="checkout-container29">
          <span class="checkout-text25"> {{ $t_("GARANTIA") }} </span>
        </div>
        <div class="checkout-container30">
          <small>(+)</small>
          <span class="checkout-text23">{{ protect_preco | moeda }}</span>
          <span class="checkout-text24">,{{ protect_preco | centavos }}</span>
        </div>
      </div>
      <div class="checkout-container37">
        <div class="checkout-container38">
          <span class="checkout-text37">{{ $t_("total") }}</span>
        </div>
        <div
          class="checkout-container39"
          v-if="
            Number($carrinho.resumo.valor) > 0 &&
            protect &&
            protect.quote_protect_group.sold
          "
        >
          <span class="checkout-text23">{{
            ($carrinho.resumo.valor + protect_preco) | moeda
          }}</span>
          <span class="checkout-text24"
            >,{{ ($carrinho.resumo.valor + protect_preco) | centavos }}</span
          >
        </div>
        <div class="checkout-container39" v-else-if="Number($carrinho.resumo.valor) > 0">
          <span class="checkout-text23">{{ $carrinho.resumo.valor | moeda }}</span>
          <span class="checkout-text24">,{{ $carrinho.resumo.valor | centavos }}</span>
        </div>
        <div class="checkout-container39" v-else>
          <span class="checkout-text23">{{ 0.0 | moeda }}</span>
          <span class="checkout-text24">,{{ 0.0 | centavos }}</span>
        </div>
      </div>
      <div v-if="protect && protect.quote_protect_group.sold == null" style="width: 100%">
        <div class="checkout-container41" style="flex-direction: column">
          <p style="text-align: center">
            {{ $t_("selecione_protect_group") }}
          </p>

          <div
            v-if="
              $config.gateway.length > 0 &&
              $config.conf &&
              $config.conf.site_key_recaptcha
            "
          >
            <vue-recaptcha
              :language="$axios.getLocal('lang').data"
              theme="red"
              size="invisible"
              @verify="$root.onCaptchaVerified"
              @expired="$root.onCaptchaExpired"
              class="mb-5"
              :sitekey="$config.conf.site_key_recaptcha"
              :loadRecaptchaScript="false"
            ></vue-recaptcha>
          </div>

          <button
            class="checkout-button1 button"
            :style="'background-color:' + $config.dados.corprimaria"
            @click="scrollTo()"
          >
            {{ $t_("selecione") }}
          </button>

          <div class="checkout-container43"></div>
          <div class="checkout-container44"></div>
        </div>
      </div>
      <div v-else class="checkout-container40">
        <div class="checkout-container41">
          <div class="checkout-container42" v-if="$axios.getLocal('session')">
            <v-text-field
              type="text"
              label="Insira o cupom"
              v-model="$carrinho.resumo.cupom"
              @input="$carrinho.resumo.cupom = $carrinho.resumo.cupom.toUpperCase()"
              outlined
              dense
              style="height: 50px"
            ></v-text-field>
            <v-btn
              elevation="0"
              style="height: 50px"
              @click="resgatarCupom($carrinho.carrinho[0].idmovimentacao)"
              :loading="loading"
            >
              {{ $t_("resgatar") }}
            </v-btn>
          </div>
          <div class="checkout-container43"></div>
          <div class="checkout-container44"></div>
        </div>
        <div
          class="checkout-container45"
          v-if="$config.gateway.length > 0"
          id="form-gateway"
        >
          <form ref="modopagamento" class="flex flex-col w-full">
            <div class="flex flex-col w-full">
              <template v-if="$carrinho.carrinho && $carrinho.carrinho[0].mov > 1">
                <label for="payment" class="block uppercase">{{
                  $t_("movimentacoes")
                }}</label
                ><br />
                <v-select
                  outlined
                  required
                  v-model="$carrinho.carrinho_finalizar_select"
                  :items="$carrinho.carrinho"
                  item-value="idmovimentacao"
                  item-text="textMovimentacoes"
                >
                </v-select>
              </template>

              <label for="payment" class="block uppercase">{{ $t_("forma_pag") }}</label
              ><br />
              <v-select
                outlined
                v-model="$config.gatewaySelected"
                required
                :items="$config.gateway"
                item-text="nome"
                return-object
              >
              </v-select>

              <template
                v-if="
                  $config.gatewaySelected &&
                  $config.gatewaySelected.parcelas &&
                  $config.gatewaySelected.parcelas.length > 0
                "
              >
                <label for="payment" class="block uppercase">{{ $t_("PARCELA") }}</label
                ><br />
                <v-select
                  outlined
                  v-model="$config.gatewaySelected.parcelaSelect"
                  required
                  :items="$config.gatewaySelected.parcelas"
                  :item-text="(v) => v.parcela + 'x ' + $util.asMoney(v.valor)"
                  return-object
                >
                  <template v-slot:item="{ item }">
                    {{ `${item.parcela}x ` }} {{ item.valor | moeda }}
                  </template>
                </v-select>
              </template>
            </div>
          </form>
        </div>
        <div
          class="div-recaptcha checkout-container45"
          v-if="
            $config.gateway.length > 0 && $config.conf && $config.conf.site_key_recaptcha
          "
        >
          <vue-recaptcha
            :language="$axios.getLocal('lang').data"
            theme="red"
            :size="$config.dados.recaptcha"
            @verify="$root.onCaptchaVerified"
            @expired="$root.onCaptchaExpired"
            class="mb-5"
            :sitekey="$config.conf.site_key_recaptcha"
            :loadRecaptchaScript="false"
          ></vue-recaptcha>
        </div>
        <div
          class="checkout-container45"
          v-if="$config.gateway.length > 0"
          id="termos-recaptcha"
        >
          <v-row justify="center" align="center">
            <v-col cols="12" md="12" class="d-flex justify-center align-center">
              <v-checkbox
                v-if="!$usuario.cadastro.termos"
                v-model="$carrinho.interesseTermos"
                class="ma-0"
                tabindex="8"
                :error-messages="$usuario.cadastro.error.termos"
                @input="$usuario.cadastro.error.termos = []"
              >
                <template v-slot:label>
                  <div>
                    {{ $t_("aceito_termos_1") }}
                    <!-- <a target='_blank' style="text-decoration:underline" @click.stop="showTermosSite = true">{{ $t_('termo_compra_e_risco')}}</a> -->
                    <a
                      target="_blank"
                      style="text-decoration: underline"
                      @click.stop="showTermosSite = true"
                      >{{ $t_("termo_compra") }}</a
                    >
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </div>
        <div class="checkout-container45" id="termos-compra">
          <button
            class="checkout-button1 button"
            :style="'background-color:' + $config.dados.corprimaria"
            @click="pagar()"
          >
            {{ $t_("finalizar_pedido") }}
          </button>
        </div>
        <div class="checkout-container45">
          <v-btn
            class="checkout-button1 button"
            to="/"
            elevation="0"
            style="height: 35px; background-color: #d9d9d9"
            outlined
            color="indigo"
          >
            <div style="color: black">{{ $t_("continuar_comprando") }}</div>
          </v-btn>
        </div>
        <div class="checkout-container46">
          <svg viewBox="0 0 1024 1024" class="checkout-icon09">
            <path
              d="M592 448h-16v-192c0-105.87-86.13-192-192-192h-128c-105.87 0-192 86.13-192 192v192h-16c-26.4 0-48 21.6-48 48v480c0 26.4 21.6 48 48 48h544c26.4 0 48-21.6 48-48v-480c0-26.4-21.6-48-48-48zM192 256c0-35.29 28.71-64 64-64h128c35.29 0 64 28.71 64 64v192h-256v-192z"
            ></path>
          </svg>
          <span class="checkout-text41">{{ $t_("site_seguro") }}</span>
        </div>
      </div>
      <div
        class="checkout-container47"
        v-if="!protect || protect.quote_protect_group.sold != null"
      >
        <div class="checkout-container48">
          <img
            alt="image"
            src="/playground_assets/bandeiras-1200h-1000h.png"
            class="checkout-image"
          />
        </div>
        <div class="checkout-container49"></div>
        <div class="checkout-container50"></div>
      </div>
    </div>
    <ModalTermos :showTermosSite="showTermosSite" @update-dialog="updateShowTermos" />
    <ModalRisco :showRisco="showRisco" @update-dialog="updateShowRisco" />

    <v-dialog v-model="finalizar" width="350" class="ma-0 pa-0" persistent></v-dialog>
  </div>
</template>

<style>
.v-snack__content {
  flex-grow: 1;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  margin-right: auto;
  padding: 14px 16px;
  text-align: center !important;
}
</style>

<script>
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "Resumo",
  props: ["protect", "protect_preco"],

  components: {
    VueRecaptcha,
    ModalTermos: () => import("@/components/modal-termos.vue"),
    ModalRisco: () => import("@/components/modal-risco.vue"),
  },
  data() {
    return {
      terms: false,
      recaptchaToken: "",
      showTermosSite: false,
      showRisco: false,
      loading: false,
      finalizar: false,
    };
  },
  watch: {
    "$carrinho.carrinho_finalizar_select"(e) {
      this.$carrinho.getFormaspagamento();
    },
    //PARA APLICAR AS TROCAS DE PARCELAS
    "$config.gatewaySelected"(e) {
      if (
        this.$config.gatewaySelected.parcelas &&
        this.$config.gatewaySelected.parcelas.length > 0
      ) {
        this.$config.gatewaySelected.parcelaSelect = this.$config.gatewaySelected.parcelas[0];
      }
    },

    "$carrinho.carrinho"(e) {
      this.carrinho = e;
      this.carrinho = this.carrinho.filter(function (item, index, array) {
        return (
          !this[
            JSON.stringify(array[index].idmovimentacao + array[index].idapresentacao)
          ] && (this[JSON.stringify(item.idmovimentacao + item.idapresentacao)] = true)
        );
      }, Object.create(null));
      const time = setInterval(() => {
        if (window.grecaptcha) {
          clearInterval(time);
          this.$config.dados.recaptcha == "visible"
            ? window.grecaptcha.reset()
            : window.grecaptcha.execute();
        }
      }, 1000);
    },
    "$carrinho.carrinho_finalizar_select"(e) {
      this.$carrinho.getFormaspagamento();
    },
  },
  methods: {
    onSucess(recaptchaToken) {
      this.$carrinho.dados.recaptcha = recaptchaToken;
    },
    onCaptchaExpired() {
      if (this.$refs.recaptcha) this.$refs.recaptcha.reset();
    },
    updateShowTermos(showDialog) {
      this.showTermosSite = showDialog;
    },
    updateShowRisco(showDialog) {
      this.showRisco = showDialog;
    },
    async resgatarCupom(idmovimentacao) {
      this.loading = true;

      // se cupom diferente de empty realiza o tratamento
      if (this.$carrinho.resumo.cupom) {
        // resgata o cupom
        const cupomResgatado = await this.$superingresso.aplicarCupom(
          idmovimentacao,
          this.$carrinho.resumo.cupom
        );
        this.loading = false;

        // se cupom resgatado
        if (cupomResgatado && cupomResgatado.desconto === true) {
          // atualiza o carrinho
          this.$carrinho.atualizaCarrinho();
        }
        return;
      }
      this.loading = false;
    },
    pagar() {
      this.finalizar = true;
      this.$carrinho.pagar();
      setTimeout(() => {
        // this.finalizar = false;
      }, 1000);
    },
    scrollTo() {
      const element = document.getElementById("checkout-protect-group");
      element.scrollIntoView({ block: "center", behavior: "smooth" });
      element.style.transform = "scale(1.03)";
      setTimeout(() => {
        element.style.transform = "scale(1)";
      }, 500);
    },
  },
  mounted() {
    // this.$carrinho.getFormaspagamento()
    const time = setInterval(() => {
      if (window.grecaptcha) {
        clearInterval(time);
        this.$config.dados.recaptcha == "visible"
          ? window.grecaptcha.reset()
          : window.grecaptcha.execute();
      }
    }, 1000);
    if (this.$axios.getLocal("cupom")) {
      this.cupomDesconto = this.$axios.getLocal("cupom").data;
    }
  },
};
</script>

<style scoped>
.div-recaptcha {
  display: none !important;
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border: var(--dl-color-gray-black) 1px solid;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.checkout-icon09 {
  fill: var(--dl-color-success-700);
  width: 24px;
  height: 24px;
}
.checkout-container23 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-start;
}

.checkout-container24 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}

.checkout-container25 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.checkout-container26 {
  flex: 0 0 auto;
  width: 60%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.checkout-container27 {
  flex: 0 0 auto;
  width: 40%;
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.checkout-container28 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.checkout-container29 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.checkout-container30 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.checkout-container31 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.checkout-container32 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.checkout-container33 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.checkout-container34 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.checkout-container35 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.checkout-container36 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.checkout-container37 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.checkout-container38 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.checkout-container39 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.checkout-container40 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.checkout-container41 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.checkout-container42 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  flex-direction: row;
}

.checkout-container43 {
  top: -11px;
  left: -15px;
  right: auto;
  width: 20px;
  bottom: auto;
  height: 20px;
  position: absolute;
  transform: rotate(230deg);
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  background-color: #eaeaea;
  border-top-width: 0px;
  border-right-width: 0px;
}

.checkout-container44 {
  top: -11px;
  left: auto;
  right: -15px;
  width: 20px;
  bottom: auto;
  height: 20px;
  position: absolute;
  transform: rotate(45deg);
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  background-color: #eaeaea;
  border-top-width: 0px;
  border-right-width: 0px;
}

.checkout-container45 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.checkout-container46 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.checkout-container47 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.checkout-container48 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.checkout-image {
  height: 37px;
  align-self: center;
  object-fit: cover;
}

.checkout-container49 {
  top: -11px;
  left: -15px;
  right: auto;
  width: 20px;
  bottom: auto;
  height: 20px;
  position: absolute;
  transform: rotate(230deg);
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  background-color: #eaeaea;
  border-top-width: 0px;
  border-right-width: 0px;
}

.checkout-container50 {
  top: -11px;
  left: auto;
  right: -15px;
  width: 20px;
  bottom: auto;
  height: 20px;
  position: absolute;
  transform: rotate(45deg);
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  background-color: #eaeaea;
  border-top-width: 0px;
  border-right-width: 0px;
}

.checkout-text20 {
  color: var(--dl-color-gray-500);
  font-size: px;
}

.checkout-text22 {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-right: 5px;
}

.checkout-text23 {
  font-style: normal;
  font-weight: 700;
}

.checkout-text24 {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.checkout-text26 {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-right: 5px;
}

.checkout-text27 {
  font-style: normal;
  font-weight: 700;
}

.checkout-text28 {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.checkout-text30 {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-right: 5px;
}

.checkout-text31 {
  font-style: normal;
  font-weight: 700;
}

.checkout-text32 {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.checkout-text34 {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-right: 5px;
}

.checkout-text35 {
  font-style: normal;
  font-weight: 700;
}

.checkout-text36 {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.checkout-text38 {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-right: 5px;
}

.checkout-text39 {
  font-style: normal;
  font-weight: 700;
}

.checkout-text40 {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.checkout-input {
  flex: 1;
  width: 217px;
  height: 35px;
  z-index: 100;
  align-self: center;
  margin-top: 5px;
  text-align: left;
  border-color: var(--dl-color-gray-900);
  margin-bottom: 5px;
}

.checkout-button {
  color: var(--dl-color-gray-white);
  width: 100px;
  height: 35px;
  font-size: 12px;
  align-self: center;
  margin-top: 0px;
  text-align: center;
  transition: 0.3s;
  padding-top: 8px;
  border-width: 0px;
  padding-bottom: 8px;
  text-transform: uppercase;
  background-color: #b5b5b5;
}
.checkout-button:hover {
  background-color: var(--dl-color-gray-black);
}

.checkout-button1 {
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 35px;
  align-self: center;
  margin-top: 0px;
  text-align: center;
  transition: 0.3s;
  padding-top: 8px;
  border-width: 0px;
  padding-bottom: 8px;
  text-transform: uppercase;
  background-color: var(--dl-color-success-500);
}
.checkout-button1:hover {
  font-size: 14px;
  background-color: var(--dl-color-success-500);
}
.checkout-button2 {
  color: var(--dl-color-gray-black);
  height: 35px;
  font-size: 12px;
  align-self: flex-end;
  margin-top: 0px;
  text-align: center;
  transition: 0.3s;
  padding-top: 8px;
  border-width: 0px;
  padding-bottom: 8px;
  text-transform: uppercase;
  background-color: var(--dl-color-secundaria);
}
.checkout-button2:hover {
  font-size: 14px;
  background-color: var(--dl-color-success-500);
}

@media (max-width: 991px) {
  .checkout-container22 {
    width: 100%;
  }

  .checkout-container42 {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .checkout-text21 {
    font-size: 12px;
  }

  .checkout-text25 {
    font-size: 12px;
  }

  .checkout-text29 {
    font-size: 12px;
  }

  .checkout-text33 {
    font-size: 12px;
  }

  .checkout-text37 {
    font-size: 12px;
  }

  .checkout-input {
    width: 100%;
  }
}
</style>
